import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useRouteError,
} from '@remix-run/react';
import type { MetaFunction } from '@remix-run/node';
import { RequiredAuthProvider } from '@propelauth/react';
import { Provider as JotaiProvider } from 'jotai';

import hounddogLogoBlack from '/logo-b.png';

import globalStyles from '~/global-styles.css?url';
import LeftSidebar from '~/components/left-sidebar';
import TopHeader from '~/components/top-header';
import { Toaster } from '~/components/ui/toaster';
import { ScrollArea, ScrollBar } from '~/components/ui/scroll-area';
import { TooltipProvider } from '~/components/ui/tooltip';
import { Button } from './components/ui/button';
import { jotaiStore } from './lib/jotai-atoms';

//
// Remix: html metadata tags
//
export const meta: MetaFunction = () => {
  return [
    { title: 'HoundDog.ai' },
    {
      name: 'description',
      content: 'Code Scanner to Detect Sensitive Data Mishandling',
    },
  ];
};

//
// Remix: html header links elements
//
export const links = () => {
  return [
    { rel: 'stylesheet', href: globalStyles },
    { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
    {
      rel: 'preconnect',
      href: 'https://fonts.gstatic.com',
      crossOrigin: '',
    },
    {
      rel: 'stylesheet',
      href: 'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap',
    },
  ];
};

//
// Remix: HydrateFallback and main loading screen
//
export function HydrateFallback() {
  return (
    <div className='flex min-h-screen flex-col items-center justify-center bg-gray-100'>
      <img
        src={hounddogLogoBlack}
        alt='hounddog.ai logo'
        className='max-w-24'
      />
      <h2 className='mt-6 text-3xl font-medium'>Loading ...</h2>
    </div>
  );
}

//
// Remix: ErrorBoundary
//
export function ErrorBoundary() {
  const error = useRouteError();

  let is404 = false;
  if (isRouteErrorResponse(error) && error.status === 404) {
    is404 = true;
  }

  captureRemixErrorBoundaryError(error);

  return (
    <div className='flex min-h-screen flex-col items-center justify-center bg-gray-100'>
      <img
        src={hounddogLogoBlack}
        alt='hounddog.ai logo'
        className='max-w-24'
      />
      <h2 className='mt-6 text-3xl font-medium'>Oops</h2>
      <p className='mt-4 text-lg'>Something went wrong</p>
      {is404 && (
        <>
          <p className='mt-4 text-lg font-medium'>404</p>
          <p className='text-lg'>Resource not found</p>
        </>
      )}
      <Button asChild className='mt-6' size='lg'>
        <a href='/'>Take me Home</a>
      </Button>
    </div>
  );
}

//
// Custom Providers wrapper
//
function Providers({ children }: { children: React.ReactNode }) {
  return (
    <RequiredAuthProvider authUrl={import.meta.env.VITE_AUTH_URL}>
      <JotaiProvider store={jotaiStore}>
        <TooltipProvider>{children}</TooltipProvider>
      </JotaiProvider>
    </RequiredAuthProvider>
  );
}

//
// Remix: Main Root Layout
//
export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang='en'>
      <head>
        <meta charSet='utf-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <Meta />
        <Links />
      </head>
      <body>
        <Providers>{children}</Providers>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

//
// Main App - (Root Layout children)
//
function App() {
  return (
    <>
      <div className='flex h-screen'>
        <LeftSidebar />
        <ScrollArea type='auto' className='flex-1 bg-gray-50'>
          <TopHeader />
          <div className='container mt-14 p-4'>
            <Outlet />
          </div>
          <ScrollBar orientation='horizontal' />
        </ScrollArea>
      </div>
      <Toaster />
    </>
  );
}

export default withSentry(App);
